var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content mb-5"},[_c('div',[_c('page-header',{attrs:{"title":_vm.$t('packages.details_title'),"sub-title":_vm.$t('packages.details_subtitle')}})],1),_c('section',{staticClass:"wameed-dashboard-page-content_body"},[_c('div',{staticClass:"mx-4"},[_c('b-row',{},[_c('b-col',{staticClass:"my-4",attrs:{"md":"6"}},[_c('package-details-table',{attrs:{"package-details":_vm.getPackageDetails}})],1),_c('b-col',{staticClass:"my-4",attrs:{"md":"6"}},[_c('package-details-images',{attrs:{"images":_vm.getPackageDetails.images,"image":_vm.getPackageDetails.image}}),_c('div',{staticClass:"package-orders"},[(_vm.getPackageDetails.others)?_c('div',{staticClass:"package-orders__buttons mb-5"},[_c('wameed-btn',{staticClass:"\n                  change-icon-position\n                  text-reg-15 text-white\n                  rounded-14\n                  px-4\n                ",class:{
                  'text-line-through': _vm.checkOrderSpecial(
                    _vm.getPackageDetails.others.feature_status, _vm.getPackageDetails.others.cancel_status
                  ),
                },attrs:{"title":_vm.$t('packages.order_special_ad'),"variant":"main","prepend-icon":"arrow-circle-left-2-icon","disabled":_vm.checkOrderSpecial(_vm.getPackageDetails.others.feature_status, _vm.getPackageDetails.others.cancel_status)},on:{"submitAction":function($event){_vm.showSpecialPackageModal = true}}}),_c('wameed-btn',{staticClass:"\n                  change-icon-position\n                  text-reg-15 text-white\n                  rounded-14\n                  px-4\n                ",class:{
                  'text-line-through': _vm.checkCancelPackage(
                    _vm.getPackageDetails.others.cancel_status
                  ),
                },attrs:{"disabled":_vm.checkCancelPackage(_vm.getPackageDetails.others.cancel_status),"title":_vm.$t('packages.order_cancel_package'),"variant":"danger","prepend-icon":"arrow-circle-left-2-icon"},on:{"submitAction":function($event){_vm.showCancelPackageModal = true}}})],1):_vm._e(),_c('special-package-modal',{attrs:{"visible":_vm.showSpecialPackageModal,"startDate":_vm.getPackageDetails.start_date},on:{"close":function($event){_vm.showSpecialPackageModal = false},"set-message":_vm.setRequestMessage}}),_c('warning-modal',{attrs:{"hide-cancel-btn":"","variant":"success","icon-name":"tick-circle-icon","visible":_vm.showSuccessModal,"title":_vm.$t('packages.request_sent'),"sub-title":_vm.$t('packages.request_sent_message'),"btn-title":_vm.$t('common.OK')},on:{"close":_vm.closeSpecialPackageModal,"submitAction":_vm.closeSpecialPackageModal}}),_c('cancel-package-modal',{attrs:{"visible":_vm.showCancelPackageModal,"package-id":_vm.getPackageDetails.id},on:{"close":function($event){_vm.showCancelPackageModal = false},"set-message":_vm.setCancellationMessage}}),(
                'others' in _vm.getPackageDetails &&
                _vm.getPackageDetails.others !== null
              )?[(_vm.getPackageDetails.others.cancel_reason !== null)?_c('div',{staticClass:"\n                  my-5\n                  package-details-table\n                  bg-white\n                  overflow-hidden\n                  rounded-14\n                "},[_c('div',{staticClass:"\n                    package-details-table__title\n                    text-font-main text-med-14\n                    d-flex\n                    justify-content-between\n                    align-items-center\n                  "},[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('packages.reason_for_cancellation')))]),_c('span',{staticClass:"text-font-secondary text-reg-12"},[_vm._v(" "+_vm._s(_vm.currentDate())+" ")])]),_c('div',{staticClass:"package-details-table__body"},[_c('p',{staticClass:"text-reg-14 text-font-secondary m-0"},[_vm._v(" "+_vm._s(_vm.getPackageDetails.others.cancel_reason)+" ")])])]):_vm._e(),(_vm.getPackageDetails.others.cancel_status !== null)?_c('cancel-status-alert',{attrs:{"state":_vm.getPackageDetails.others.cancel_status}}):_vm._e(),(_vm.getPackageDetails.others.feature_status !== null)?_c('special-package-status-alert',{attrs:{"state":_vm.getPackageDetails.others.feature_status}}):_vm._e(),(_vm.getPackageDetails.others.admin_cancel_message !== null)?_c('reason-message',{attrs:{"date":_vm.getPackageDetails.others.cancel_date,"text":_vm.getPackageDetails.others.admin_cancel_message}}):_vm._e(),(_vm.getPackageDetails.others.admin_feature_message !== null)?_c('reason-message',{attrs:{"date":_vm.getPackageDetails.others.feature_date,"text":_vm.getPackageDetails.others.admin_feature_message,"is-special-package":""}}):_vm._e()]:_vm._e()],2)],1)],1)],1),_c('fetch-service-bookings',{attrs:{"id":_vm.serviceId}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }