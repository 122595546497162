var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",staticClass:"repeater__form",staticStyle:{"overflow":"visible"},attrs:{"tag":"form"}},[_c('b-modal',{attrs:{"size":"lg","content-class":"package-modal mx-4","no-fade":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('template',{slot:"modal-header-close"},[_c('close-icon')],1),_c('template',{slot:"modal-title"},[_c('div',{staticClass:"d-block text-center"},[_c('h3',{staticClass:"text-reg-18"},[_vm._v(" "+_vm._s(_vm.$t('packages.map_address'))+" ")])])]),_c('div',{staticClass:"w-100 rounded-8 position-relative"},[_c('GmapMap',{ref:"map",staticClass:"rounded-8",staticStyle:{"width":"100%","height":"356px","border-radius":"8px"},attrs:{"center":_vm.center,"zoom":17,"map-type-id":"roadmap","options":{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: true,
        }}},[(_vm.marker)?_c('GmapMarker',{attrs:{"position":_vm.marker,"draggable":false}}):_vm._e()],1)],1),_c('template',{slot:"modal-footer"},[_c('wameed-btn',{attrs:{"block":"","classes":"  text-med-14 text-font-secondary mx-0 rounded-10 ","title":_vm.$t('common.cancel'),"type":"button","variant":"gray"},on:{"submitAction":function($event){_vm.show = false}}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }